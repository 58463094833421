@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: 'Lexend Deca', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#09091D ;
  background: url('https://improved-bucket.s3.eu-central-1.amazonaws.com/castle_complete_JPEG.jpg') no-repeat center center fixed;
  background-size: cover;

}

h1{
  margin-block-start: 0;
  margin-block-end: 0;
}



code {
  font-family: 'Lexend Deca', sans-serif;
}

.container{
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

}




.fullwidthScreen{
  height: 100vh;
  width: 100vw;


  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

background-color: rgba(0,0,0,0.5);
  


}

.transak{
  height: 80%;
  width: 100%;
  max-width: 400px;
  max-height: 500px;

  border-radius: 10px;
  overflow: hidden;
  
  box-shadow: 0px 0px 20px 0px rgb(0, 0, 0);

}


.heroSubtitle{
  font-size: 45px;
  color: white;
  max-width: 800px;
  text-align: center;
  line-height: 1;
}


.heroTitle {
  font-size: 4em;
  color: white;
  max-width: 100%;
  text-align: center;
  margin: 20px 0px 50px 0px;
  text-shadow: 0px 0px 10px white;
  animation: shadowPulse 3s infinite;
}

@keyframes shadowPulse {
  0%, 100% {
    text-shadow: 0px 0px 10px white;
  }
  50% {
    text-shadow: 0px 0px 20px white;
  }
}



.logo{
  object-fit: contain;
  width: 75px;
  height: 75px;
}

.titleContainer{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  width: 100%;

}